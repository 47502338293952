body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  color: white;
  font-size: calc(15px + 2vmin);
  background-color: #292929;
  background-image: url(./poppilig.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}

#root {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
header {
  margin-top: 20px;
}

header img {
  width: 20vw;
}

main {
  display: flex;
  flex-wrap: wrap;
  max-width: 75vw;
  margin: 5vw 0;
}

.infoBox {
  background-color: #2e2e2e;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 230px;
  min-height: 10vh;
  margin-bottom: 30px;
  margin-right: 30px;
  padding: 30px;
  border-radius: 45px;
}
.infoBox:nth-child(1) {
  background-image: linear-gradient(to top, #0ba360 0%, #3cba92 100%);
}
.infoBox:nth-child(2) {
  background-image: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
}
.infoBox:nth-child(3) {
  background-image: linear-gradient(to right, #6a11cb 0%, #2575fc 100%);
}
.infoBox:nth-child(4) {
  background-image: linear-gradient(to top, #f77062 0%, #fe5196 100%);
}
.infoBox:nth-child(5) {
  background-image: linear-gradient(-225deg, #A445B2 0%, #D41872 52%, #FF0066 100%);
}
.infoBox:nth-child(6) {
  background-image: linear-gradient(-225deg, #473B7B 0%, #3584A7 51%, #30D2BE 100%);
}

.infoBox span:last-child {
  color: rgb(216, 216, 216);
  font-weight:bold;
  font-size: 1.2rem;
  /* margin-bottom: 5px; */
}
.infoBox span:first-child {
  font-weight: lighter;
  color: #fff;
}

/*###############################
# Footer
###############################*/

footer p a, footer {
  color: #fff;
  font-size: 1rem;
}